import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'league',
    loadChildren: () =>
      import('./pages/league/league.module').then((m) => m.LeaguePageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'local-scoreboard',
    loadChildren: () =>
      import('./pages/tablescoring/scoreboard/scoreboard.module').then(
        (m) => m.ScoreboardPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'eventadmin',
    loadChildren: () =>
      import('./pages/eventadmin/eventadmin.module').then(
        (m) => m.EventadminPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'tablescoring',
    loadChildren: () =>
      import('./pages/tablescoring/tablescoring.module').then(
        (m) => m.TablescoringPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./pages/event-tabs/event-tabs.module').then(
        (m) => m.EventTabsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
