import { ConfettiService } from './../confetti.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable, OnInit } from '@angular/core';
import {
  ToastController,
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';
import {
  Glassfy,
  GlassfyOffering,
  GlassfyPermission,
  GlassfySku,
  GlassfyTransaction,
  GLASSFY_STORE,
  GLASSFY_ELEGIBILITY,
} from 'capacitor-plugin-glassfy';
import { ProductsComponent } from '../components/products/products.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from './data.service';

interface UserSubscriptionStatus {
  available: boolean;
  pro: boolean;
  basic: boolean;
  sku: GlassfySku | undefined; // sku is either a GlassfySku object or an empty string
}

@Injectable({
  providedIn: 'root',
})
export class ProductService implements OnInit {
  // define a type for our behavior subject values

  private user = new BehaviorSubject<UserSubscriptionStatus>({
    available: false,
    pro: false,
    basic: false,
    sku: undefined,
  });
  // user$ = this.user.asObservable();
  user$ = combineLatest([
    this.user.asObservable(),
    this.dataService.currentUser,
  ]).pipe(
    map(([userStatus, currentUser]) => {
      if (userStatus.available && currentUser) {
        if (currentUser?.email === 'demo@snapdown.io') {
          return { ...userStatus, basic: false, pro: true };
        }
        if (currentUser?.email === 'demo2@snapdown.io') {
          return { ...userStatus, pro: false, basic: true };
        }
        if (currentUser?.email === 'robert@erafx.com') {
          return { ...userStatus, pro: true, basic: false };
        }
      }
      return userStatus;
    }),
  );

  private offerings$: BehaviorSubject<GlassfyOffering[]> = new BehaviorSubject<
    GlassfyOffering[]
  >([]);

  errors: string[] = [];
  isNative = false;
  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private confettiService: ConfettiService,
    public platform: Platform,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
  ) {
    // console.log('init glassfy');
    this.isNative = platform.is('capacitor');
    this.initGlassfy();
  }

  spinnerShow() {
    this.spinner.show('blank');
  }
  spinnerHide() {
    this.spinner.hide('blank');
  }
  async showProductModal() {
    let modal = await this.modalCtrl.create({
      component: ProductsComponent,
      breakpoints: [0, 1.0],
      initialBreakpoint: 1.0,
      // cssClass: 'auto-height',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      // console.log('confirm button');
    }
  }

  async ngOnInit() {
    // await this.initGlassfy();
  }

  async initGlassfy() {
    let isAvailable = false;
    try {
      // Initialise Glassfy
      await Glassfy.initialize({
        apiKey: environment.glassfyKey,
        watcherMode: false,
      });

      // Get all user permissions
      const permissions = await Glassfy.permissions();
      this.handleExistingPermissions(permissions.all);

      // Get all offerings (products)
      const offerings = await Glassfy.offerings();
      // console.log('glassfy OFFERINGS...', JSON.stringify(offerings));
      this.errors.push('glassfy OFFERINGS...', JSON.stringify(offerings));
      isAvailable = true;
      this.offerings$.next(offerings.all);
    } catch (e) {
      console.log('glassfy init error: ', e);
      this.errors.push('glassfy init error: ', e as string);
      if (!this.isNative) {
        // add fake offerings for testing
        const sampleOffering: GlassfyOffering[] = [
          {
            offeringId: 'Snapdown Pro',
            skus: [
              {
                skuId: '1month_monthly_sku_001',
                productId: 'product12345',
                store: GLASSFY_STORE.AppStore, // Assuming APP_STORE is one of the GLASSFY_STORE values
                introductoryEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
                promotionalEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming NOT_ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
                extravars: {
                  sampleKey: 'sampleValue',
                },
                product: {
                  title: 'Full Access (1 Month)',
                  identifier: 'product12345',
                  description: 'Subscribe on a monthly basis.',
                  price: 4.99,
                  currencyCode: 'USD',
                  period: '1 month',
                  introductoryPrice: {
                    identifier: 'intro_monthly_001',
                    price: 4.99,
                    currencyCode: 'USD',
                    period: '1 month',
                    numberOfPeriods: 1,
                    type: 'introductory',
                  },
                  discounts: [],
                  basePlanId: 'base_plan_123', // Add a suitable base plan ID
                },
                basePlanId: 'base_plan_123', // Add a suitable base plan ID
                offerId: 'offer_123', // Add a suitable offer ID
                discount: {
                  identifier: 'discount_123',
                  price: 3.99,
                  currencyCode: 'USD',
                  period: '1 month',
                  numberOfPeriods: 1,
                  type: 'discountType', // Replace with actual discount type
                },
              },

              {
                skuId: 'yearly_sku_001',
                productId: 'product99999',
                store: GLASSFY_STORE.AppStore, // Assuming APP_STORE is one of the GLASSFY_STORE values
                introductoryEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
                promotionalEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming NOT_ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
                extravars: {
                  sampleKey: 'sampleValueYearly',
                },
                product: {
                  title: 'Full Access (1 Year)',
                  identifier: 'product99999',
                  description:
                    'Subscribe for a whole year and get the best value!',
                  price: 49.99,
                  currencyCode: 'USD',
                  period: '12 months',
                  basePlanId: 'base_plan_123', // Add a suitable base plan ID

                  introductoryPrice: {
                    identifier: 'intro_yearly_001',
                    price: 79.99,
                    currencyCode: 'USD',
                    period: '12 months',
                    numberOfPeriods: 12,
                    type: 'introductory',
                  },
                  discounts: [
                    {
                      identifier: 'discount_yearly_001',
                      price: 84.99,
                      currencyCode: 'USD',
                      period: '12 months',
                      numberOfPeriods: 12,
                      type: 'promotional',
                    },
                  ],
                },
                basePlanId: 'base_plan_123', // Add a suitable base plan ID
                offerId: 'offer_123', // Add a suitable offer ID
                discount: {
                  identifier: 'discount_123',
                  price: 3.99,
                  currencyCode: 'USD',
                  period: '1 month',
                  numberOfPeriods: 1,
                  type: 'discountType', // Replace with actual discount type
                },
              },
            ],
          },
          {
            offeringId: 'Snapdown Basic',
            skus: [
              {
                skuId: '1month_monthly_sku_001',
                productId: 'product12345',
                store: GLASSFY_STORE.AppStore, // Assuming APP_STORE is one of the GLASSFY_STORE values
                introductoryEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
                promotionalEligibility: GLASSFY_ELEGIBILITY.NON_ELEGIBLE, // Assuming NOT_ELIGIBLE is one of the GLASSFY_ELEGIBILITY values
                extravars: {
                  sampleKey: 'sampleValue',
                },
                product: {
                  title: 'Basic Access (1 Month)',
                  identifier: 'product12345',
                  description: 'Subscribe on a monthly basis.',
                  price: 2.99,
                  currencyCode: 'USD',
                  period: '1 month',
                  basePlanId: 'base_plan_123', // Add a suitable base plan ID

                  introductoryPrice: {
                    identifier: 'intro_monthly_001',
                    price: 4.99,
                    currencyCode: 'USD',
                    period: '1 month',
                    numberOfPeriods: 1,
                    type: 'introductory',
                  },
                  discounts: [],
                },
                basePlanId: 'base_plan_123', // Add a suitable base plan ID
                offerId: 'offer_123', // Add a suitable offer ID
                discount: {
                  identifier: 'discount_123',
                  price: 3.99,
                  currencyCode: 'USD',
                  period: '1 month',
                  numberOfPeriods: 1,
                  type: 'discountType', // Replace with actual discount type
                },
              },
            ],
          },
        ];
        this.offerings$.next(sampleOffering);
      }
    }

    // log whether the offerings are even available on this platform
    const user = this.user.getValue();
    user.available = isAvailable;

    // hack for local testing

    // TODO fixme
    // user.basic = false;
    // user.pro = true;
    // user.available = true;

    this.user.next(user);
  }

  async purchase(sku: GlassfySku) {
    this.spinnerShow();

    // const user = this.user.getValue();
    // let's reset to base state
    // user.pro = false;
    // user.basic = true;
    // user.sku = undefined;
    // this.user.next(user);

    try {
      let transaction;
      let lastSku = this.user.getValue().sku;
      if (lastSku) {
        let skuToUpgrade = this.user.getValue().sku;
        console.log('glassfy, user has sku, upgrading...', skuToUpgrade?.skuId);
        transaction = await Glassfy.purchaseSku({ sku, skuToUpgrade });
      } else {
        console.log('glassfy, user no sku...');
        transaction = await Glassfy.purchaseSku({ sku });
      }
      console.log('glassfy, transaction', JSON.stringify(transaction));
      this.errors.push('glassfy, transaction', JSON.stringify(transaction));

      // if (transaction.receiptValidated) {
      this.handleSuccessfulTransactionResult(transaction, sku);
      // } else {
      // this.errors.push('not receiptValidated?')
      // }
    } catch (e) {
      console.log('glassfy, purchase error', e);
      const alert = await this.alertController.create({
        header: 'Purchase failed',
        message: e as string,
        buttons: ['OK'],
      });

      await alert.present();
    }
    this.spinnerHide();
  }

  handleExistingPermissions(permissions: GlassfyPermission[]) {
    console.log(
      'glassfy: Permissions: user has perms',
      JSON.stringify(permissions),
    );
    const user = this.user.getValue();

    // let's reset to base state
    user.pro = false;
    user.basic = false;
    user.sku = undefined;

    for (const perm of permissions) {
      if (perm.isValid) {
        if (perm.permissionId === 'pro_features') {
          user.pro = true;
          console.log('accountable skus', perm.accountableSkus);
          if (perm.accountableSkus.length > 0) {
            user.sku = perm.accountableSkus[0] as unknown as GlassfySku;
          }
        }
        if (perm.permissionId === 'basic_access') {
          console.log('accountable skus', perm.accountableSkus);
          if (perm.accountableSkus.length > 0) {
            user.sku = perm.accountableSkus[0] as unknown as GlassfySku;
          }

          user.basic = true;
        }
      }
    }
    console.log('user', user);
    this.user.next(user);
  }

  async handleSuccessfulTransactionResult(
    transaction: GlassfyTransaction,
    sku: GlassfySku,
  ) {
    this.errors.push('handle successful...');
    // if (transaction.productId.indexOf('snapdown_pro_') >= 0) {
    //   const user = this.user.getValue();
    //   user.pro = true;
    //   this.user.next(user);
    // }
    this.handleExistingPermissions(transaction.permissions.all);

    this.confettiService.realistic();

    const toast = await this.toastController.create({
      message: 'Thanks for your purchase!',
      position: 'bottom',
      duration: 2000,
    });
    toast.present();
  }

  // Helper functions
  getOfferings() {
    return this.offerings$.asObservable();
  }

  async restore() {
    this.spinnerShow();
    const permissions = await Glassfy.restorePurchases();
    this.spinnerHide();
    console.log(permissions);
    this.handleExistingPermissions(permissions.all);
    // Handle those permissions!
  }
}
