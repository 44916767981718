import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Location } from '@angular/common';
import { Keyboard } from '@capacitor/keyboard';

import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loggedIn = false;
  dark = false;
  showSplash = true;

  constructor(
    private authService: AuthService,
    private productService: ProductService,
    private platform: Platform,
    private zone: NgZone,
    private location: Location,
  ) {
    // swiper
    register();

    setTimeout(() => {
      if (platform.is('capacitor')) {
        Keyboard.setAccessoryBarVisible({ isVisible: true });
      }
    }, 100);

    this.initializeApp();
    this.setupListener();

    if (environment.mode == 'dev') {
      // make dev purple
      document.documentElement.style.setProperty(
        '--ion-color-primary',
        '#333333',
      );
    }

    this.authService.currentUser.subscribe((user) => {
      if (user) {
        this.loggedIn = true;
      }
    });

    timer(1200).subscribe(() => (this.showSplash = false));
  }

  logout() {
    this.authService.logout();
  }

  async setupListener() {
    // Listen for our snapdown:// urls. We are only supporting redirect to home.
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      const openUrl = data.url;
      const slug = openUrl.split('home/').pop();
      const navigateUrl = `/home${slug}`;

      this.zone.run(() => {
        this.location.replaceState(navigateUrl);
        window.location.reload();
      });
    });
  }
  async initializeApp() {
    this.platform.ready().then(async () => {
      // this.statusBar.styleDefault();
      console.log('splash screen hide');

      await SplashScreen.hide();
    });
  }
  ngOnInit() {}
}
