// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer a {
  padding: 5px;
}

ion-card.features {
  padding: 5px;
}

ul {
  margin-block-start: 0.2em;
  padding-inline-start: 24px;
}

.links {
  font-size: 0.8em;
}

.note {
  font-size: 0.9em;
  color: var(--ion-color-medium);
}

.features {
  font-size: 0.9em;
  padding: 6px;
  background-color: var(--ion-color-light);
}

ion-card.offering {
  margin-top: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
ion-card.offering ion-card-content {
  padding: 5px;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/products/products.component.scss"],"names":[],"mappings":"AACI;EAAG,YAAA;AACP;;AAEA;EACI,YAAA;AACJ;;AACA;EACI,yBAAA;EACA,0BAAA;AAEJ;;AACA;EAAS,gBAAA;AAGT;;AAAA;EACI,gBAAA;EACA,8BAAA;AAGJ;;AADA;EACI,gBAAA;EACA,YAAA;EACA,wCAAA;AAIJ;;AAFE;EACE,gBAAA;EACA,2CAAA;AAKJ;AAJI;EACI,YAAA;EACA,gBAAA;AAMR","sourcesContent":["ion-footer {\n    a {padding: 5px;}\n}\n\nion-card.features {\n    padding:5px;\n}\nul {\n    margin-block-start: 0.2em;\n    padding-inline-start: 24px;\n}\n\n.links { font-size: 0.8em;}\n\n\n.note {\n    font-size: 0.9em;\n    color: var(--ion-color-medium);\n  }\n.features {\n    font-size:0.9em;\n    padding: 6px;\n    background-color: var(--ion-color-light);\n}\n  ion-card.offering {\n    margin-top: 10px;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);\n    ion-card-content{\n        padding: 5px;\n        font-size: 0.9em;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
