import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

/*
    Shows confetti animations, just for fun.
    See: https://www.kirilv.com/canvas-confetti/
*/
@Injectable({
  providedIn: 'root',
})
export class ConfettiService {
  constructor() {}

  public canon(): void {
    confetti({
      angle: this.randomInRange(55, 125),
      spread: this.randomInRange(50, 70),
      particleCount: this.randomInRange(100, 200),
      origin: { y: 0.6 },
    });
  }

  count = 200;
  defaults = {
    origin: { y: 0.7 },
  };

  fire(particleRatio:number, opts:any) {
    confetti(
      Object.assign({}, this.defaults, opts, {
        particleCount: Math.floor(this.count * particleRatio),
      })
    );
  }

  public realistic() {
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    this.fire(0.2, {
      spread: 60,
    });
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }
  private randomInRange(min:number, max:number) {
    return Math.random() * (max - min) + min;
  }
}
